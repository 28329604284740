import { Employee } from '@app/shared/classes/employee';
import { GetEncodedEmployeesResponse } from '@app/shared/models/api/encoded-employees/get-encoded-employees';
import { Action, createReducer, on } from '@ngrx/store';
import { IEmployeesState } from '@storeModule/employees/employees-state.interface';
import {
  addEmployee,
  addEmployeesSuccess,
  addEmployeeSuccess,
  changeStatusOfEmployeeSuccess,
  clearImportResult,
  deselectEmployee,
  editEmployee,
  editEmployeesSuccess,
  editEmployeeSuccess,
  getEmployeesOffsetSuccess,
  getEmployeesSuccess,
  getEmployeeSuccess,
  importEmployees,
  importEmployeesSuccess
} from '@storeModule/employees/employees.actions';
import { adapter, initialState } from '@storeModule/employees/employees.state';

export function reducer(state: IEmployeesState | undefined, action: Action) {
  return employeesReducer(state, action);
}

const employeesReducer = createReducer(
  initialState,
  on(getEmployeesOffsetSuccess, (state: IEmployeesState, props: { encodedEmployees: GetEncodedEmployeesResponse }) => {
    return {
      ...state,
      pendingEmployees: [...state.pendingEmployees, ...props.encodedEmployees]
    };
  }),
  on(getEmployeesSuccess, (state: IEmployeesState, props: { employees: Array<Employee> }) => {
    state.loaded = true;
    state.pendingEmployees = null;
    return adapter.addAll(props.employees, state);
  }),
  on(getEmployeeSuccess, (state: IEmployeesState, props: { employee: Employee }) => {
    state.selectedEmployee = props.employee;
    if (state.entities[props.employee.id]) {
      return adapter.updateOne({
        id: props.employee.id,
        changes: props.employee
      }, state);
    } else {
      return adapter.addOne(props.employee, state);
    }
  }),
  on(deselectEmployee, (state: IEmployeesState) => {
    return {...state, selectedEmployee: null};
  }),
  on(changeStatusOfEmployeeSuccess, (state: IEmployeesState, props: { employee: Employee }) => {
    return adapter.updateOne({
      id: props.employee.id,
      changes: props.employee
    }, state);
  }),
  on(addEmployee, (state: IEmployeesState) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(addEmployeeSuccess, (state: IEmployeesState, props: { employee: Employee }) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.employee, state);
  }),
  on(editEmployee, (state: IEmployeesState) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(editEmployeeSuccess, (state: IEmployeesState, props: { employee: Employee }) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
      id: props.employee.id,
      changes: props.employee
    }, state);
  }),
  on(importEmployees, (state: IEmployeesState) => {
    return {
      ...state,
      import: {
        ...state.import,
        inProgress: true
      }
    };
  }),
  on(importEmployeesSuccess, (state: IEmployeesState, props: { added: Array<Employee>, edited: Array<Employee>, notValid: Array<{employee: Employee, reason: string}> }) => {
    return {
      ...state,
      import: {
        ...state.import,
        result: {added: props.added, edited: props.edited, notValid: props.notValid},
        inProgress: false
      }
    };
  }),
  on(clearImportResult, (state: IEmployeesState) => {
    return {
      ...state,
      import: {
        ...state.import,
        result: null
      }
    };
  }),
  on(addEmployeesSuccess, (state: IEmployeesState, props: { employees: Array<Employee> }) => {
    return adapter.addMany(props.employees, state);
  }),
  on(editEmployeesSuccess, (state: IEmployeesState, props: { employees: Array<Employee> }) => {
    return adapter.updateMany(props.employees.map((employee) => {
      return {
        id: employee.id,
        changes: employee
      };
    }), state);
  }),
);
