import { createReducer, on } from '@ngrx/store';
import { addEmployee, addEmployeesSuccess, addEmployeeSuccess, changeStatusOfEmployeeSuccess, clearImportResult, deselectEmployee, editEmployee, editEmployeesSuccess, editEmployeeSuccess, getEmployeesOffsetSuccess, getEmployeesSuccess, getEmployeeSuccess, importEmployees, importEmployeesSuccess } from '@storeModule/employees/employees.actions';
import { adapter, initialState } from '@storeModule/employees/employees.state';
export function reducer(state, action) {
    return employeesReducer(state, action);
}
const ɵ0 = (state, props) => {
    return Object.assign({}, state, { pendingEmployees: [...state.pendingEmployees, ...props.encodedEmployees] });
}, ɵ1 = (state, props) => {
    state.loaded = true;
    state.pendingEmployees = null;
    return adapter.addAll(props.employees, state);
}, ɵ2 = (state, props) => {
    state.selectedEmployee = props.employee;
    if (state.entities[props.employee.id]) {
        return adapter.updateOne({
            id: props.employee.id,
            changes: props.employee
        }, state);
    }
    else {
        return adapter.addOne(props.employee, state);
    }
}, ɵ3 = (state) => {
    return Object.assign({}, state, { selectedEmployee: null });
}, ɵ4 = (state, props) => {
    return adapter.updateOne({
        id: props.employee.id,
        changes: props.employee
    }, state);
}, ɵ5 = (state) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ6 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.employee, state);
}, ɵ7 = (state) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ8 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
        id: props.employee.id,
        changes: props.employee
    }, state);
}, ɵ9 = (state) => {
    return Object.assign({}, state, { import: Object.assign({}, state.import, { inProgress: true }) });
}, ɵ10 = (state, props) => {
    return Object.assign({}, state, { import: Object.assign({}, state.import, { result: { added: props.added, edited: props.edited, notValid: props.notValid }, inProgress: false }) });
}, ɵ11 = (state) => {
    return Object.assign({}, state, { import: Object.assign({}, state.import, { result: null }) });
}, ɵ12 = (state, props) => {
    return adapter.addMany(props.employees, state);
}, ɵ13 = (state, props) => {
    return adapter.updateMany(props.employees.map((employee) => {
        return {
            id: employee.id,
            changes: employee
        };
    }), state);
};
const employeesReducer = createReducer(initialState, on(getEmployeesOffsetSuccess, ɵ0), on(getEmployeesSuccess, ɵ1), on(getEmployeeSuccess, ɵ2), on(deselectEmployee, ɵ3), on(changeStatusOfEmployeeSuccess, ɵ4), on(addEmployee, ɵ5), on(addEmployeeSuccess, ɵ6), on(editEmployee, ɵ7), on(editEmployeeSuccess, ɵ8), on(importEmployees, ɵ9), on(importEmployeesSuccess, ɵ10), on(clearImportResult, ɵ11), on(addEmployeesSuccess, ɵ12), on(editEmployeesSuccess, ɵ13));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
