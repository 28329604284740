import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyEffects } from '@storeModule/company/company.effects';

import { reducer } from '@storeModule/company/company.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('company', reducer),
    EffectsModule.forFeature([CompanyEffects])
  ],
  exports: [StoreModule]
})
export class CompanyStoreModule {
}
