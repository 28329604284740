import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProfileResponse } from '@app/shared/models/api/profile/profile-response';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileApiService {

  constructor(private httpClient: HttpClient) {
  }

  getProfile(): Observable<IProfileResponse> {
    return this.httpClient.get<IProfileResponse>(`${environment.apiBaseUrl}/management/v1/profile`);
  }
}

