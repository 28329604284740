import { KeyLoadedBy } from '@app/shared/enums/key-loaded-by.enum';
import { Action, createReducer, on } from '@ngrx/store';
import { ICompanyKeyState } from '@storeModule/company-key/company-key-state.interface';
import {
  generateCompanyKeySuccess,
  removeCompanyKey,
  saveCompanyKey,
  uploadCompanyKeyFail,
  uploadCompanyKeySuccess
} from '@storeModule/company-key/company-key.actions';
import { companyKeyState } from '@storeModule/company-key/company-key.state';

export function reducer(state: ICompanyKeyState | undefined, action: Action) {
  return companyKeyReducer(state, action);
}

const companyKeyReducer = createReducer(
  companyKeyState,
  on(saveCompanyKey, (state: ICompanyKeyState, props: { CKey: string }) => {
    return {...state, CKey: props.CKey, isKeyLoaded: true};
  }),
  on(removeCompanyKey, (state: ICompanyKeyState) => {
    return {...state, CKey: null, isKeyLoaded: false, keyLoadingBy: null};
  }),
  on(generateCompanyKeySuccess, (state: ICompanyKeyState) => {
    return {...state, keyLoadingBy: KeyLoadedBy.Generation};
  }),
  on(uploadCompanyKeySuccess, (state: ICompanyKeyState) => {
    return {...state, keyLoadingBy: KeyLoadedBy.Uploading};
  }),
  on(uploadCompanyKeyFail, (state: ICompanyKeyState) => {
    return {...state, keyLoadingBy: KeyLoadedBy.Uploading};
  })
);
