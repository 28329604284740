import { Employee } from '@app/shared/classes/employee';
import { EncodedByCKey, EncodedByEKey } from '@app/shared/classes/encoded-data-of-employee';
import { EncodedEmployeeRequest } from '@app/shared/classes/encoded-employee-request';
import { EmployeeStatus } from '@app/shared/enums/encoded-employee-status.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/services/crypt/crypt.service";
export class ManageEncodedEmployeeService {
    constructor(cryptService) {
        this.cryptService = cryptService;
    }
    prepareEmployeeRequestAfterChangeStatus(employee, CKey) {
        employee.status = employee.status === EmployeeStatus.Active ? EmployeeStatus.Deactivated : EmployeeStatus.Active;
        return this.prepareEmployeeToRequest(employee, CKey);
    }
    prepareEmployeeToRequest(employee, CKey) {
        return new EncodedEmployeeRequest(employee.status || EmployeeStatus.Inactive, employee.employeeNumber, employee.linkedToMWAccount, this.cryptService.hashData(employee.email.toLowerCase()), this.cryptService.encrypt(JSON.stringify(new EncodedByEKey(employee.firstName, employee.lastName, employee.DOB, employee.email, employee.phoneNumber, employee.country, employee.city, employee.postCode, employee.addressLine1, employee.addressLine2, employee.wageTypeId, employee.wageValue, employee.bankCountryCode, employee.bankSortCode, employee.bankAccountNumber, employee.securityNumber, employee.payrollNumber, employee.employmentStartDate, employee.employmentEndDate)), this.cryptService.keyBase64ToKeyBytes(employee.EKey)), this.cryptService.encrypt(JSON.stringify(new EncodedByCKey(employee.firstName, employee.lastName, employee.DOB, employee.email, employee.phoneNumber, employee.country, employee.city, employee.postCode, employee.addressLine1, employee.addressLine2, employee.wageTypeId, employee.wageValue, employee.bankCountryCode, employee.bankSortCode, employee.bankAccountNumber, employee.securityNumber, employee.payrollNumber, employee.employmentStartDate, employee.employmentEndDate, employee.EKey)), CKey));
    }
    decode(employee, CKey) {
        let decodedData;
        try {
            const decodedString = this.cryptService.decrypt(employee.encodedByCKey, CKey);
            decodedData = JSON.parse(decodedString);
            return new Employee(employee.id, decodedData.firstName, decodedData.lastName, employee.status, employee.employeeNumber, employee.mwAgreementDone, employee.created, employee.mwAgreementDate, employee.linkedToMWAccount, employee.linkedToMWAccountDate, decodedData.DOB, decodedData.email, decodedData.phoneNumber, decodedData.country, decodedData.city, decodedData.postCode, decodedData.addressLine1, decodedData.addressLine2, decodedData.wageTypeId, decodedData.wageValue, decodedData.bankCountryCode, decodedData.bankSortCode, decodedData.bankAccountNumber, decodedData.securityNumber, decodedData.payrollNumber, decodedData.employmentStartDate, decodedData.employmentEndDate, decodedData.EKey);
        }
        catch (_a) {
            return new Employee(employee.id, 'CAN\'T DECODE', 'CAN\'T DECODE', employee.status, employee.employeeNumber, employee.mwAgreementDone, employee.created, employee.mwAgreementDate, employee.linkedToMWAccount, employee.linkedToMWAccountDate);
        }
    }
}
ManageEncodedEmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageEncodedEmployeeService_Factory() { return new ManageEncodedEmployeeService(i0.ɵɵinject(i1.CryptService)); }, token: ManageEncodedEmployeeService, providedIn: "root" });
