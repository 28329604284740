import { ICompanyAddress } from '@app/shared/models/company-address';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICompanyAddressesState } from '@storeModule/company-addresses/company-addresses-state.interface';
import { adapter } from '@storeModule/company-addresses/company-addresses.state';

export const selectCompanyAddressesState = createFeatureSelector<ICompanyAddressesState>('companyAddresses');

export const selectIsLoaded = (state: ICompanyAddressesState) => state.loaded;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


export const selectCompanyAddressIds = createSelector(
  selectCompanyAddressesState,
  selectIds
);
export const selectCompanyAddressEntities = createSelector(
  selectCompanyAddressesState,
  selectEntities
);
export const selectAllCompanyAddresses = createSelector(
  selectCompanyAddressesState,
  selectAll
);
export const selectCompanyAddressesTotal = createSelector(
  selectCompanyAddressesState,
  selectTotal
);
export const selectIsCompanyAddressesLoaded = createSelector(
  selectCompanyAddressesState,
  selectIsLoaded
);
export const isAddOrEditInProgress = createSelector(
  selectCompanyAddressesState,
  (state: ICompanyAddressesState) => state.isAddOrEditInProgress
);
export const selectSelectedCompanyAddress = createSelector(
  selectCompanyAddressesState,
  (state: ICompanyAddressesState) => state.selectedCompanyAddress
);
export const selectCompanyAddressById = createSelector(
  selectCompanyAddressEntities,
  (state: Dictionary<ICompanyAddress>, id) => state[id]
);
