import { ICreateCompanyContactRequest } from '@app/shared/models/api/companies/create-company-contact';
import { IUpdateCompanyContactRequest } from '@app/shared/models/api/companies/update-company-contact';
import { ICompanyContact } from '@app/shared/models/company-contact';
import { Action, createReducer, on } from '@ngrx/store';
import { ICompanyContactsState } from '@storeModule/company-contacts/company-contacts-state.interface';
import {
  addCompanyContact,
  addCompanyContactSuccess,
  deselectCompanyContact,
  editCompanyContact,
  editCompanyContactSuccess,
  getCompanyContactsSuccess,
  getCompanyContactSuccess
} from '@storeModule/company-contacts/company-contacts.actions';
import { adapter, initialState } from '@storeModule/company-contacts/company-contacts.state';

export function reducer(state: ICompanyContactsState | undefined, action: Action) {
  return companyContactsReducer(state, action);
}

const companyContactsReducer = createReducer(
  initialState,
  on(getCompanyContactsSuccess, (state: ICompanyContactsState, props: { companyContacts: Array<ICompanyContact> }) => {
    state.loaded = true;
    return adapter.addAll(props.companyContacts, state);
  }),
  on(getCompanyContactSuccess, (state: ICompanyContactsState, props: { companyContact: ICompanyContact }) => {
    state.selectedCompanyContact = props.companyContact;
    if (state.entities[props.companyContact.id]) {
      return adapter.updateOne({
        id: props.companyContact.id,
        changes: props.companyContact
      }, state);
    } else {
      return adapter.addOne(props.companyContact, state);
    }
  }),
  on(deselectCompanyContact, (state: ICompanyContactsState, props) => {
    return {...state, selectedCompanyContact: null};
  }),
  on(addCompanyContact, (state: ICompanyContactsState, props: { companyContact: ICreateCompanyContactRequest }) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(addCompanyContactSuccess, (state: ICompanyContactsState, props: { companyContact: ICompanyContact }) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.companyContact, state);
  }),
  on(editCompanyContact, (state: ICompanyContactsState, props: { id: string, companyContact: IUpdateCompanyContactRequest }) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(editCompanyContactSuccess, (state: ICompanyContactsState, props: { companyContact: ICompanyContact }) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
      id: props.companyContact.id,
      changes: props.companyContact
    }, state);
  }),
);
