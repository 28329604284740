import { ICompanyContact } from '@app/shared/models/company-contact';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ICompanyContactsState } from '@storeModule/company-contacts/company-contacts-state.interface';

export const adapter: EntityAdapter<ICompanyContact> = createEntityAdapter<ICompanyContact>({
  // selectId: selectGUID,
  // sortComparer: sortByName,
});

export const initialState: ICompanyContactsState = adapter.getInitialState({
  loaded: null,
  selectedCompanyContact: null,
  isAddOrEditInProgress: false
});
