import { ICountry } from '@app/shared/models/country';
import { IDictionaryEntity } from '@app/shared/models/dictionary-entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IDictionariesState } from '@storeModule/dictionaries/dictionaries-state.interface';
import {
  getAddressTypesSuccess,
  getContactTypesSuccess,
  getCountriesSuccess,
  getSectorsSuccess,
  getWageTypesSuccess
} from '@storeModule/dictionaries/dictionaries.actions';
import { dictionariesState } from '@storeModule/dictionaries/dictionaries.state';

export function reducer(state: IDictionariesState | undefined, action: Action) {
  return dictionariesReducer(state, action);
}

const dictionariesReducer = createReducer(
  dictionariesState,
  on(getAddressTypesSuccess, (state: IDictionariesState, props: { addressTypes: Array<IDictionaryEntity> }) => {
    const preparedData = prepareEntitiesData(props.addressTypes);
    return {...state, addressTypes: {...state.addressTypes, entities: preparedData.entities, ids: preparedData.ids, loaded: true}};
  }),
  on(getContactTypesSuccess, (state: IDictionariesState, props: { contactTypes: Array<IDictionaryEntity> }) => {
    const preparedData = prepareEntitiesData(props.contactTypes);
    return {...state, contactTypes: {...state.contactTypes, entities: preparedData.entities, ids: preparedData.ids, loaded: true}};
  }),
  on(getWageTypesSuccess, (state: IDictionariesState, props: { wageTypes: Array<IDictionaryEntity> }) => {
    const preparedData = prepareEntitiesData(props.wageTypes);
    return {...state, wageTypes: {...state.wageTypes, entities: preparedData.entities, ids: preparedData.ids, loaded: true}};
  }),
  on(getSectorsSuccess, (state: IDictionariesState, props: { sectors: Array<IDictionaryEntity> }) => {
    const preparedData = prepareEntitiesData(props.sectors);
    return {...state, sectors: {...state.sectors, entities: preparedData.entities, ids: preparedData.ids, loaded: true}};
  }),
  on(getCountriesSuccess, (state: IDictionariesState, props: { countries: Array<ICountry> }) => {
    const preparedData = prepareCountriesData(props.countries);
    return {...state, countries: {...state.countries, entities: preparedData.entities, ids: preparedData.ids, loaded: true}};
  }),
);

function prepareEntitiesData(data: Array<IDictionaryEntity>) {
  const ids = [];
  const entities = {};

  data.forEach((item, i) => {
    ids.push(item.id);
    entities[item.id] = item;
  });

  return {ids, entities};
}

function prepareCountriesData(data: Array<ICountry>) {
  const ids = [];
  const entities = {};

  data.forEach((item, i) => {
    ids.push(item.isoCode);
    entities[item.isoCode] = item;
  });

  return {ids, entities};
}
