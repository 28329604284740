import { CompanyStatus } from '@app/shared/enums/company-status.enum';

export class Company {
  id: string = null;
  name: string = null;
  sectorId: number = null;
  vatNumber: string = null;
  registerNumber: string = null;
  defaultDrawDownPercentage: number = null;
  loanPercentageCommission: number = null;
  defaultResetDay: number = null;
  fixedRateCommission: number = null;
  status: CompanyStatus = null;
  created: number = null;
  clearingAccountBankCountryIsoCode: string = null;
  clearingAccountBankSortCode: string = null;
  clearingAccountBankAccountNumber: string = null;
  keyControl: string = null;

  constructor(companyData?: Company) {
    if (companyData) {
      for (const property in companyData) {
        if (companyData.hasOwnProperty(property) && this.hasOwnProperty(property)) {
          this[property] = companyData[property];
        }
      }
    }
  }
}
