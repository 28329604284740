import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetCountriesResponse } from '@app/shared/models/api/dictionaries/get-countries';

import { GetDictionaryEntityResponse } from '@app/shared/models/api/dictionaries/get-dictionary-entity';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable()
export class DictionariesApiService {

  constructor(private httpClient: HttpClient) {
  }

  getAddressTypes(): Observable<GetDictionaryEntityResponse> {
    return this.httpClient.get<GetDictionaryEntityResponse>(`${environment.apiBaseUrl}/management/v1/dictionaries/types/addresses`);
  }

  getContactTypes(): Observable<GetDictionaryEntityResponse> {
    return this.httpClient.get<GetDictionaryEntityResponse>(`${environment.apiBaseUrl}/management/v1/dictionaries/types/contacts`);
  }

  getWageTypes(): Observable<GetDictionaryEntityResponse> {
    return this.httpClient.get<GetDictionaryEntityResponse>(`${environment.apiBaseUrl}/management/v1/dictionaries/types/wages`);
  }

  getSectors(): Observable<GetDictionaryEntityResponse> {
    return this.httpClient.get<GetDictionaryEntityResponse>(`${environment.apiBaseUrl}/management/v1/dictionaries/sectors`);
  }

  getCountries(): Observable<GetCountriesResponse> {
    return this.httpClient.get<GetCountriesResponse>(`${environment.apiBaseUrl}/management/v1/dictionaries/countries`);
  }
}
