import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth/auth-api.service';
import { CompaniesApiService } from '@app/core/api/companies/companies-api.service';
import { DictionariesApiService } from '@app/core/api/dictionaries/dictionaries-api.service';
import { EncodedEmployeesApiService } from '@app/core/api/encoded-employees/encoded-employees-api.service';
import { ErrorInterceptor } from '@app/core/api/interceptors/error.interceptor';
import { TokenInterceptor } from '@app/core/api/interceptors/token.interceptor';
import { MockApiModule } from '@app/core/api/mock-api/mock-api.module';
import { ProfileApiService } from '@app/core/api/profile/profile-api.service';
import { environment } from '@environment';
import { SendingMailsApiService } from '@app/core/api/sending-mails/sending-mails-api.service';

const mockApiModule = environment.mockApi ? [MockApiModule] : [];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...mockApiModule
  ],
  providers: [
    AuthApiService,
    CompaniesApiService,
    DictionariesApiService,
    EncodedEmployeesApiService,
    ProfileApiService,
    SendingMailsApiService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ]
})
export class ApiModule {
}
