import { ICompanyAddress } from '@app/shared/models/company-address';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ICompanyAddressesState } from '@storeModule/company-addresses/company-addresses-state.interface';

export const adapter: EntityAdapter<ICompanyAddress> = createEntityAdapter<ICompanyAddress>({});

export const initialState: ICompanyAddressesState = adapter.getInitialState({
  loaded: null,
  selectedCompanyAddress: null,
  isAddOrEditInProgress: false
});
