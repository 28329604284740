import { Injectable } from '@angular/core';
import { DictionariesApiService } from '@app/core/api/dictionaries/dictionaries-api.service';
import { ICountry } from '@app/shared/models/country';
import { IDictionaryEntity } from '@app/shared/models/dictionary-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getAddressTypes,
  getAddressTypesSuccess,
  getContactTypes,
  getContactTypesSuccess,
  getCountries,
  getCountriesSuccess,
  getSectors,
  getSectorsSuccess,
  getWageTypes,
  getWageTypesSuccess
} from '@storeModule/dictionaries/dictionaries.actions';
import {
  selectAddressTypesState,
  selectContactTypesState,
  selectCountriesState,
  selectSectorsState,
  selectWageTypesState
} from '@storeModule/dictionaries/dictionaries.selectors';
import { IEntityState } from '@storeModule/dictionaries/entity-state.interface';
import { IStoreState } from '@storeModule/store-state';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class DictionariesEffects {
  getAddressTypes$ = createEffect(() => this.actions$
    .pipe(
      ofType(getAddressTypes),
      withLatestFrom(this.store.select(selectAddressTypesState)),
      map(([action, addressTypesState]) => addressTypesState),
      filter((addressTypesState: IEntityState) => !addressTypesState.loaded),
      switchMap(() => this.dictionariesApiService.getAddressTypes()),
      map((addressTypes: Array<IDictionaryEntity>) => getAddressTypesSuccess({addressTypes}))
    )
  );

  getContactTypes$ = createEffect(() => this.actions$
    .pipe(
      ofType(getContactTypes),
      withLatestFrom(this.store.select(selectContactTypesState)),
      map(([action, contactTypesState]) => contactTypesState),
      filter((contactTypesState: IEntityState) => !contactTypesState.loaded),
      switchMap(() => this.dictionariesApiService.getContactTypes()),
      map((contactTypes: Array<IDictionaryEntity>) => getContactTypesSuccess({contactTypes}))
    ));

  getWageTypes$ = createEffect(() => this.actions$
    .pipe(
      ofType(getWageTypes),
      withLatestFrom(this.store.select(selectWageTypesState)),
      filter(([action, wageTypesState]) => !wageTypesState.loaded),
      switchMap(() => this.dictionariesApiService.getWageTypes()),
      map((wageTypes: Array<IDictionaryEntity>) => getWageTypesSuccess({wageTypes}))
    ));

  getSectors$ = createEffect(() => this.actions$
    .pipe(
      ofType(getSectors),
      withLatestFrom(this.store.select(selectSectorsState)),
      filter(([action, sectorsState]) => !sectorsState.loaded),
      switchMap(() => this.dictionariesApiService.getSectors()),
      map((sectors: Array<IDictionaryEntity>) => getSectorsSuccess({sectors}))
    ));

  getCountries$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCountries),
      withLatestFrom(this.store.select(selectCountriesState)),
      filter(([action, countriesState]) => !countriesState.loaded),
      switchMap(() => this.dictionariesApiService.getCountries()),
      map((countries: Array<ICountry>) => getCountriesSuccess({countries}))
    ));

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private dictionariesApiService: DictionariesApiService
  ) {
  }
}
