import { Employee } from '@app/shared/classes/employee';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployeesState } from '@storeModule/employees/employees-state.interface';
import { adapter } from '@storeModule/employees/employees.state';

export const selectEmployeesState = createFeatureSelector<IEmployeesState>('employees');

export const selectIsLoaded = (state: IEmployeesState) => state.loaded;
export const selectIsAddOrEditInProgress = (state: IEmployeesState) => state.isAddOrEditInProgress;
export const selectEmployee = (state: IEmployeesState) => state.selectedEmployee;
export const selectPendingEmployees = (state: IEmployeesState) => state.pendingEmployees;
export const selectImport = (state: IEmployeesState) => state.import;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


export const selectEmployeeIds = createSelector(
  selectEmployeesState,
  selectIds
);
export const selectEmployeeEntities = createSelector(
  selectEmployeesState,
  selectEntities
);
export const selectAllEmployees = createSelector(
  selectEmployeesState,
  selectAll
);
export const selectEmployeesTotal = createSelector(
  selectEmployeesState,
  selectTotal
);
export const selectIsEmployeesLoaded = createSelector(
  selectEmployeesState,
  selectIsLoaded
);
export const isAddOrEditInProgress = createSelector(
  selectEmployeesState,
  selectIsAddOrEditInProgress
);
export const selectSelectedEmployee = createSelector(
  selectEmployeesState,
  selectEmployee
);
export const selectEmployeeById = createSelector(
  selectEmployeeEntities,
  (state: Dictionary<Employee>, id: string) => state[id]
);
export const selectPendingToDecodeEmployees = createSelector(
  selectEmployeesState,
  selectPendingEmployees
);
export const selectImportState = createSelector(
  selectEmployeesState,
  selectImport
);
export const selectIsImportInProgress = createSelector(
  selectImportState,
  (state) => state.inProgress
);
export const selectImportResult = createSelector(
  selectImportState,
  (state) => state.result
);
