import { ICreateCompanyContactRequest } from '@app/shared/models/api/companies/create-company-contact';
import { IUpdateCompanyContactRequest } from '@app/shared/models/api/companies/update-company-contact';
import { ICompanyContact } from '@app/shared/models/company-contact';
import { createAction, props } from '@ngrx/store';

export const getCompanyContacts = createAction('[CompanyContacts] Get company contacts');
export const getCompanyContactsSuccess =
  createAction('[CompanyContacts] Get company contacts is success', props<{ companyContacts: Array<ICompanyContact> }>());

export const getCompanyContact = createAction('[CompanyContacts] Get company contact', props<{ id: string }>());
export const getCompanyContactSuccess = createAction('[CompanyContacts] Get company contact is success', props<{ companyContact: ICompanyContact }>());

export const deselectCompanyContact = createAction('[CompanyContacts] Deselect company contact');

export const addCompanyContact = createAction('[CompanyContacts] Add company contact', props<{ companyContact: ICreateCompanyContactRequest }>());
export const addCompanyContactSuccess = createAction('[CompanyContacts] Add company contact is success', props<{ companyContact: ICompanyContact }>());

export const editCompanyContact = createAction('[CompanyContacts] Edit company contact', props<{ id: string, companyContact: IUpdateCompanyContactRequest }>());
export const editCompanyContactSuccess = createAction('[CompanyContacts] Edit company contact is success', props<{ companyContact: ICompanyContact }>());
