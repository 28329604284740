import { Injectable } from '@angular/core';
import { CompaniesApiService } from '@app/core/api/companies/companies-api.service';
import { ICompanyContact } from '@app/shared/models/company-contact';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  addCompanyContact,
  addCompanyContactSuccess,
  editCompanyContact,
  editCompanyContactSuccess,
  getCompanyContact,
  getCompanyContacts,
  getCompanyContactsSuccess,
  getCompanyContactSuccess
} from '@storeModule/company-contacts/company-contacts.actions';
import { selectIsCompanyContactsLoaded } from '@storeModule/company-contacts/company-contacts.selectors';
import { IStoreState } from '@storeModule/store-state';
import { ToastrService } from 'ngx-toastr';
import { concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationTypes } from '@employerPortalModule/shared/enums/notification-types';

@Injectable()
export class CompanyContactsEffects {
  getCompanyContacts$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCompanyContacts),
      withLatestFrom(this.store.select(selectIsCompanyContactsLoaded)),
      filter(([action, isCompanyContactsLoaded]: [Action, boolean]) => !isCompanyContactsLoaded),
      concatMap(() => this.companiesApiService.getCompanyContacts()
        .pipe(
          map((companyContacts: Array<ICompanyContact>) => getCompanyContactsSuccess({companyContacts}))
        )),
    ));
  getCompanyContact$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCompanyContact),
      concatMap(action => this.companiesApiService.getCompanyContact(action.id)),
      map((companyContact: ICompanyContact) => getCompanyContactSuccess({companyContact}))
    ));
  addCompanyContact$ = createEffect(() => this.actions$
    .pipe(
      ofType(addCompanyContact),
      concatMap(action => this.companiesApiService.createCompanyContact(action.companyContact)),
      map((companyContact: ICompanyContact) => addCompanyContactSuccess({companyContact}))
    ));
  addCompanyContactSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(addCompanyContactSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successCompanyContactAdded))
    ), {dispatch: false});
  editCompanyContact$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompanyContact),
      concatMap(action => this.companiesApiService.updateCompanyContact(action.id, action.companyContact)),
      map((companyContact: ICompanyContact) => editCompanyContactSuccess({companyContact}))
    ));
  editCompanyContactSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompanyContactSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successCompanyContactEdited))
    ), {dispatch: false});

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private companiesApiService: CompaniesApiService,
    private toastr: ToastrService
  ) {
  }
}
