import { ICountry } from '@app/shared/models/country';
import { IDictionaryEntity } from '@app/shared/models/dictionary-entity';
import { createAction, props } from '@ngrx/store';

export const getAddressTypes = createAction('[Dictionaries] Get address types');
export const getAddressTypesSuccess =
  createAction('[Dictionaries] Get address types is success', props<{ addressTypes: Array<IDictionaryEntity> }>());

export const getContactTypes = createAction('[Dictionaries] Get contact types');
export const getContactTypesSuccess =
  createAction('[Dictionaries] Get contact types is success', props<{ contactTypes: Array<IDictionaryEntity> }>());

export const getWageTypes = createAction('[Dictionaries] Get wage types');
export const getWageTypesSuccess =
  createAction('[Dictionaries] Get wage types is success', props<{ wageTypes: Array<IDictionaryEntity> }>());

export const getSectors = createAction('[Dictionaries] Get sectors');
export const getSectorsSuccess = createAction('[Dictionaries] Get sectors is success', props<{ sectors: Array<IDictionaryEntity> }>());

export const getCountries = createAction('[Dictionaries] Get countries');
export const getCountriesSuccess = createAction('[Dictionaries] Get countries is success', props<{ countries: Array<ICountry> }>());
