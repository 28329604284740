import { Injectable } from '@angular/core';

import { CompaniesApiService } from '@app/core/api/companies/companies-api.service';
import { Company } from '@app/shared/classes/company';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { loginFailure } from '@storeModule/auth/auth.actions';
import { editCompany, editCompanySuccess, getCompany, getCompanySuccess } from '@storeModule/company/company.actions';

import { IStoreState } from '@storeModule/store-state';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { NotificationTypes } from '@employerPortalModule/shared/enums/notification-types';

@Injectable()
export class CompanyEffects {
  getCompany$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCompany),
      concatMap(() => this.companiesApiService.getCompany()
        .pipe(
          map((company: Company) => getCompanySuccess({company: new Company(company)})),
          catchError(() => of(loginFailure()))
        )),
    ));

  editCompany$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompany),
      concatMap((action) => this.companiesApiService.updateCompany(action.company)
        .pipe(
          map((company: Company) => editCompanySuccess({company: new Company(company)}))
        )),
    ));
  editCompanySuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompanySuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successCompanyEdited))
    ), {dispatch: false});

  constructor(
    private store: Store<IStoreState>,
    private companiesApiService: CompaniesApiService,
    private actions$: Actions,
    private toastr: ToastrService
  ) {
  }
}
