import { Injectable } from '@angular/core';
import { Employee } from '@app/shared/classes/employee';
import { EncodedByCKey, EncodedByEKey } from '@app/shared/classes/encoded-data-of-employee';
import { EncodedEmployeeRequest } from '@app/shared/classes/encoded-employee-request';
import { EmployeeStatus } from '@app/shared/enums/encoded-employee-status.enum';
import { IUpdateEncodedEmployeeRequest } from '@app/shared/models/api/encoded-employees/update-encoded-employee';
import { IEncodedEmployee } from '@app/shared/models/encoded-employee';
import { CryptService } from '@app/shared/services/crypt/crypt.service';

@Injectable({
  providedIn: 'root'
})
export class ManageEncodedEmployeeService {

  constructor(private cryptService: CryptService) {
  }

  prepareEmployeeRequestAfterChangeStatus(employee: Employee,
                                          CKey: string): IUpdateEncodedEmployeeRequest {
    employee.status = employee.status === EmployeeStatus.Active ? EmployeeStatus.Deactivated : EmployeeStatus.Active;

    return this.prepareEmployeeToRequest(employee, CKey);
  }

  prepareEmployeeToRequest(employee: Employee,
                           CKey: string): IUpdateEncodedEmployeeRequest {

    return new EncodedEmployeeRequest(
      employee.status || EmployeeStatus.Inactive,
      employee.employeeNumber,
      employee.linkedToMWAccount,
      this.cryptService.hashData(employee.email.toLowerCase()),
      this.cryptService.encrypt(JSON.stringify(new EncodedByEKey(
        employee.firstName,
        employee.lastName,
        employee.DOB,
        employee.email,
        employee.phoneNumber,
        employee.country,
        employee.city,
        employee.postCode,
        employee.addressLine1,
        employee.addressLine2,
        employee.wageTypeId,
        employee.wageValue,
        employee.bankCountryCode,
        employee.bankSortCode,
        employee.bankAccountNumber,
        employee.securityNumber,
        employee.payrollNumber,
        employee.employmentStartDate,
        employee.employmentEndDate
      )), this.cryptService.keyBase64ToKeyBytes(employee.EKey)),
      this.cryptService.encrypt(JSON.stringify(new EncodedByCKey(
        employee.firstName,
        employee.lastName,
        employee.DOB,
        employee.email,
        employee.phoneNumber,
        employee.country,
        employee.city,
        employee.postCode,
        employee.addressLine1,
        employee.addressLine2,
        employee.wageTypeId,
        employee.wageValue,
        employee.bankCountryCode,
        employee.bankSortCode,
        employee.bankAccountNumber,
        employee.securityNumber,
        employee.payrollNumber,
        employee.employmentStartDate,
        employee.employmentEndDate,
        employee.EKey
      )), CKey)
    );
  }

  decode(employee: IEncodedEmployee, CKey: string): Employee {
    let decodedData: EncodedByCKey;

    try {
      const decodedString = this.cryptService.decrypt(employee.encodedByCKey, CKey);
      decodedData = JSON.parse(decodedString);

      return new Employee(
        employee.id,
        decodedData.firstName,
        decodedData.lastName,
        employee.status,
        employee.employeeNumber,
        employee.mwAgreementDone,
        employee.created,
        employee.mwAgreementDate,
        employee.linkedToMWAccount,
        employee.linkedToMWAccountDate,
        decodedData.DOB,
        decodedData.email,
        decodedData.phoneNumber,
        decodedData.country,
        decodedData.city,
        decodedData.postCode,
        decodedData.addressLine1,
        decodedData.addressLine2,
        decodedData.wageTypeId,
        decodedData.wageValue,
        decodedData.bankCountryCode,
        decodedData.bankSortCode,
        decodedData.bankAccountNumber,
        decodedData.securityNumber,
        decodedData.payrollNumber,
        decodedData.employmentStartDate,
        decodedData.employmentEndDate,
        decodedData.EKey
      );
    } catch {
      return new Employee(
        employee.id,
        'CAN\'T DECODE',
        'CAN\'T DECODE',
        employee.status,
        employee.employeeNumber,
        employee.mwAgreementDone,
        employee.created,
        employee.mwAgreementDate,
        employee.linkedToMWAccount,
        employee.linkedToMWAccountDate);
    }
  }
}
