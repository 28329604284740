import { createSelector } from '@ngrx/store';
import { ICompanyKeyState } from '@storeModule/company-key/company-key-state.interface';
import { IStoreState } from '@storeModule/store-state';

export const selectKeyState = (state: IStoreState): ICompanyKeyState => state.companyKey;

export const selectIsKeyLoaded = createSelector(
  selectKeyState,
  (companyKey: ICompanyKeyState) => companyKey.isKeyLoaded
);

export const selectCKey = createSelector(
  selectKeyState,
  (companyKey: ICompanyKeyState) => companyKey.CKey
);
