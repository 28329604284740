import { Employee } from '@app/shared/classes/employee';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IEmployeesState } from '@storeModule/employees/employees-state.interface';

export const adapter: EntityAdapter<Employee> = createEntityAdapter<Employee>({});

export const initialState: IEmployeesState = adapter.getInitialState({
  loaded: null,
  selectedEmployee: null,
  isAddOrEditInProgress: false,
  pendingEmployees: [],
  import: {
    result: null,
    inProgress: false
  }
});
