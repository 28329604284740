export enum NotificationTypes {
  successCompanyEdited = 'Company Successfully Edited',
  successCompanyAddressAdded = 'Company Address Successfully Created',
  successCompanyAddressEdited = 'Company Address Successfully Edited',
  successCompanyContactAdded = 'Company Contact Successfully Created',
  successCompanyContactEdited = 'Company Contact Successfully Edited',
  successEmployeeAdded = 'Employee Successfully Created',
  successEmployeeEdited = 'Employee Successfully Edited',
  successEmployeesImported = 'Employees Successfully Imported',
  successEmailSent = 'Email Successfully Sent',
}
