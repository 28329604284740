export class EmailInfo {
  id: string = null;
  firstName: string = null;
  lastName: string = null;
  EKey: string = null;
  email: string = null;

  constructor(id: string, email: string, EKey: string, firstName: string, lastName: string) {
    this.id = id;
    this.email = email;
    this.EKey = EKey;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
