import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AsideStoreModule } from '@storeModule/aside/aside-store.module';
import { AuthStoreModule } from '@storeModule/auth/auth-store.module';
import { CompanyAddressesStoreModule } from '@storeModule/company-addresses/company-addresses-store.module';
import { CompanyContactsStoreModule } from '@storeModule/company-contacts/company-contacts-store.module';
import { CompanyKeyStoreModule } from '@storeModule/company-key/company-key-store.module';
import { CompanyStoreModule } from '@storeModule/company/company-store.module';
import { DictionariesStoreModule } from '@storeModule/dictionaries/dictionaries-store.module';
import { EmployeesStoreModule } from '@storeModule/employees/employees-store.module';
import { ScreenStoreModule } from '@storeModule/screen/screen-store.module';
import { storeDevtools } from '@storeModule/store-devtools';

import { UserStoreModule } from '@storeModule/user/user-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserStoreModule,
    AuthStoreModule,
    AsideStoreModule,
    ScreenStoreModule,
    CompanyStoreModule,
    CompanyKeyStoreModule,
    CompanyContactsStoreModule,
    CompanyAddressesStoreModule,
    EmployeesStoreModule,
    DictionariesStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ...storeDevtools
  ]
})
export class StorageModule {
}
