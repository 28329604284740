import { createReducer, on } from '@ngrx/store';
import { addCompanyAddress, addCompanyAddressSuccess, deselectCompanyAddress, editCompanyAddress, editCompanyAddressSuccess, getCompanyAddressesSuccess, getCompanyAddressSuccess } from '@storeModule/company-addresses/company-addresses.actions';
import { adapter, initialState } from '@storeModule/company-addresses/company-addresses.state';
export function reducer(state, action) {
    return companyAddressesReducer(state, action);
}
const ɵ0 = (state, props) => {
    state.loaded = true;
    return adapter.addAll(props.companyAddresses, state);
}, ɵ1 = (state, props) => {
    state.selectedCompanyAddress = props.companyAddress;
    if (state.entities[props.companyAddress.id]) {
        return adapter.updateOne({
            id: props.companyAddress.id,
            changes: props.companyAddress
        }, state);
    }
    else {
        return adapter.addOne(props.companyAddress, state);
    }
}, ɵ2 = (state) => {
    return Object.assign({}, state, { selectedCompanyAddress: null });
}, ɵ3 = (state, props) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ4 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.companyAddress, state);
}, ɵ5 = (state, props) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ6 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
        id: props.companyAddress.id,
        changes: props.companyAddress
    }, state);
};
const companyAddressesReducer = createReducer(initialState, on(getCompanyAddressesSuccess, ɵ0), on(getCompanyAddressSuccess, ɵ1), on(deselectCompanyAddress, ɵ2), on(addCompanyAddress, ɵ3), on(addCompanyAddressSuccess, ɵ4), on(editCompanyAddress, ɵ5), on(editCompanyAddressSuccess, ɵ6));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
