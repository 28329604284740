import { createSelector } from '@ngrx/store';
import { IDictionariesState } from '@storeModule/dictionaries/dictionaries-state.interface';
import { IStoreState } from '@storeModule/store-state';

export const selectDictionaries = (state: IStoreState): IDictionariesState => state.dictionaries;

export const selectAddressTypesState = createSelector(
  selectDictionaries,
  (dictionaries: IDictionariesState) => dictionaries.addressTypes
);

export const selectContactTypesState = createSelector(
  selectDictionaries,
  (dictionaries: IDictionariesState) => dictionaries.contactTypes
);

export const selectWageTypesState = createSelector(
  selectDictionaries,
  (dictionaries: IDictionariesState) => dictionaries.wageTypes
);

export const selectSectorsState = createSelector(
  selectDictionaries,
  (dictionaries: IDictionariesState) => dictionaries.sectors
);

export const selectCountriesState = createSelector(
  selectDictionaries,
  (dictionaries: IDictionariesState) => dictionaries.countries
);


// export const selectAddressTypesIds = createSelector(
//   selectDictionaries,
//   (dictionaries: IDictionariesState) => dictionaries.addressTypes.ids
// );
// export const selectAddressTypesEntities = createSelector(
//   selectDictionaries,
//   (dictionaries: IDictionariesState) => dictionaries.addressTypes.entities
// );
// export const selectAllAddressTypes = createSelector(
//   selectDictionaries,
//   (dictionaries: IDictionariesState) => {
//     const list = []
//     dictionaries.addressTypes.ids.forEach((id) => list.push(dictionaries.addressTypes.entities[id]));
//     return list;
//   }
// );
// export const selectAddressTypesTotal = createSelector(
//   selectDictionaries,
//   (dictionaries: IDictionariesState) => dictionaries.addressTypes.ids.length
// );
// export const selectIsAddressTypesLoaded = createSelector(
//   selectDictionaries,
//   (dictionaries: IDictionariesState) => dictionaries.addressTypes.loaded
// );
