import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmailRequest } from '@app/shared/models/api/email/email-request';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable()
export class SendingMailsApiService {
  readonly emailUrl = `${environment.apiEmailUrl}`;
  readonly baseUrl = `${environment.apiBaseUrl}/client`;

  constructor(private httpClient: HttpClient) {
  }

  sendMails(request: Array<IEmailRequest>): Observable<void> {
    return this.httpClient.post<void>(`${this.emailUrl}/api/v1/sendMail`, request);
  }

  getAgreeUrl(employeeId: string, EKey: string, redirectTo: string): string {
    return `${this.baseUrl}/v1/agreement?employeeId=${employeeId}&key=${EKey}&redirectTo=${redirectTo}`;
  }
}
