import { Company } from '@app/shared/classes/company';
import { Action, createReducer, on } from '@ngrx/store';
import { ICompanyState } from '@storeModule/company/company-state.interface';
import { editCompany, editCompanySuccess, getCompanySuccess, removeCompany } from '@storeModule/company/company.actions';
import { companyState } from '@storeModule/company/company.state';

export function reducer(state: ICompanyState | undefined, action: Action) {
  return companyReducer(state, action);
}

const companyReducer = createReducer(
  companyState,
  on(getCompanySuccess, (state: ICompanyState, props: { company: Company }) => {
    return {...state, company: props.company};
  }),
  on(editCompany, (state: ICompanyState, props: { company: Company }) => {
    return {...state, isEditInProgress: true};
  }),
  on(editCompanySuccess, (state: ICompanyState, props: { company: Company }) => {
    return {...state, company: props.company, isEditInProgress: false};
  }),
  on(removeCompany, (state: ICompanyState) => {
    return {...state, company: new Company()};
  })
);
