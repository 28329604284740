import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyContactsEffects } from '@storeModule/company-contacts/company-contacts.effects';
import { reducer } from '@storeModule/company-contacts/company-contacts.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('companyContacts', reducer),
    EffectsModule.forFeature([CompanyContactsEffects])
  ]
})
export class CompanyContactsStoreModule {
}
