import { IDictionariesState } from '@storeModule/dictionaries/dictionaries-state.interface';

export const dictionariesState: IDictionariesState = {
  addressTypes: {
    loaded: null,
    ids: [],
    entities: {}
  },
  contactTypes: {
    loaded: null,
    ids: [],
    entities: {}
  },
  wageTypes: {
    loaded: null,
    ids: [],
    entities: {}
  },
  sectors: {
    loaded: null,
    ids: [],
    entities: {}
  },
  countries: {
    loaded: null,
    ids: [],
    entities: {}
  },
};
