import { IUpdateEncodedEmployeeRequest } from '@app/shared/models/api/encoded-employees/update-encoded-employee';

export class EncodedEmployeeRequest implements IUpdateEncodedEmployeeRequest {
  status: string;
  employeeNumber: string;
  linkedToMWAccount?: boolean;
  emailHash: string;
  encodedByCKey: string;
  encodedByEKey: string;

  constructor(status: string,
              employeeNumber: string,
              linkedToMWAccount: boolean,
              emailHash: string,
              encodedByEKey: string,
              encodedByCKey: string) {

    this.status = status;
    this.employeeNumber = employeeNumber;

    if (linkedToMWAccount !== null) {
      this.linkedToMWAccount = linkedToMWAccount;
    }

    this.emailHash = emailHash;
    this.encodedByCKey = encodedByCKey;
    this.encodedByEKey = encodedByEKey;
  }
}

