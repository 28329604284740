import { Injectable } from '@angular/core';
import { CompaniesApiService } from '@app/core/api/companies/companies-api.service';
import { ICompanyAddress } from '@app/shared/models/company-address';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  addCompanyAddress,
  addCompanyAddressSuccess,
  editCompanyAddress,
  editCompanyAddressSuccess,
  getCompanyAddress,
  getCompanyAddresses,
  getCompanyAddressesSuccess,
  getCompanyAddressSuccess
} from '@storeModule/company-addresses/company-addresses.actions';
import { selectIsCompanyAddressesLoaded } from '@storeModule/company-addresses/company-addresses.selectors';
import { IStoreState } from '@storeModule/store-state';
import { ToastrService } from 'ngx-toastr';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationTypes } from '@employerPortalModule/shared/enums/notification-types';

@Injectable()
export class CompanyAddressesEffects {
  getCompanyAddresses$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCompanyAddresses),
      withLatestFrom(this.store.select(selectIsCompanyAddressesLoaded)),
      filter(([action, isCompanyAddressesLoaded]: [Action, boolean]) => !isCompanyAddressesLoaded),
      switchMap(() => this.companiesApiService.getCompanyAddresses()),
      map((companyAddresses: Array<ICompanyAddress>) => getCompanyAddressesSuccess({companyAddresses}))
    ));
  getCompanyAddress$ = createEffect(() => this.actions$
    .pipe(
      ofType(getCompanyAddress),
      concatMap(action => this.companiesApiService.getCompanyAddress(action.id)),
      map((companyAddress: ICompanyAddress) => getCompanyAddressSuccess({companyAddress}))
    ));
  addCompanyAddress$ = createEffect(() => this.actions$
    .pipe(
      ofType(addCompanyAddress),
      concatMap(action => this.companiesApiService.createCompanyAddress(action.companyAddress)),
      map((companyAddress: ICompanyAddress) => addCompanyAddressSuccess({companyAddress}))
    ));
  addCompanyAddressSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(addCompanyAddressSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successCompanyAddressAdded))
    ), {dispatch: false});
  editCompanyAddress$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompanyAddress),
      concatMap(action => this.companiesApiService.updateCompanyAddress(action.id, action.companyAddress)),
      map((companyAddress: ICompanyAddress) => editCompanyAddressSuccess({companyAddress}))
    ));
  editCompanyAddressSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(editCompanyAddressSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successCompanyAddressEdited))
    ), {dispatch: false});

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private companiesApiService: CompaniesApiService,
    private toastr: ToastrService
  ) {
  }
}
