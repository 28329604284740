import { createReducer, on } from '@ngrx/store';
import { addCompanyContact, addCompanyContactSuccess, deselectCompanyContact, editCompanyContact, editCompanyContactSuccess, getCompanyContactsSuccess, getCompanyContactSuccess } from '@storeModule/company-contacts/company-contacts.actions';
import { adapter, initialState } from '@storeModule/company-contacts/company-contacts.state';
export function reducer(state, action) {
    return companyContactsReducer(state, action);
}
const ɵ0 = (state, props) => {
    state.loaded = true;
    return adapter.addAll(props.companyContacts, state);
}, ɵ1 = (state, props) => {
    state.selectedCompanyContact = props.companyContact;
    if (state.entities[props.companyContact.id]) {
        return adapter.updateOne({
            id: props.companyContact.id,
            changes: props.companyContact
        }, state);
    }
    else {
        return adapter.addOne(props.companyContact, state);
    }
}, ɵ2 = (state, props) => {
    return Object.assign({}, state, { selectedCompanyContact: null });
}, ɵ3 = (state, props) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ4 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.companyContact, state);
}, ɵ5 = (state, props) => {
    return Object.assign({}, state, { isAddOrEditInProgress: true });
}, ɵ6 = (state, props) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
        id: props.companyContact.id,
        changes: props.companyContact
    }, state);
};
const companyContactsReducer = createReducer(initialState, on(getCompanyContactsSuccess, ɵ0), on(getCompanyContactSuccess, ɵ1), on(deselectCompanyContact, ɵ2), on(addCompanyContact, ɵ3), on(addCompanyContactSuccess, ɵ4), on(editCompanyContact, ɵ5), on(editCompanyContactSuccess, ɵ6));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
