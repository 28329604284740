import { ICreateCompanyAddressRequest } from '@app/shared/models/api/companies/create-company-address';
import { IUpdateCompanyAddressRequest } from '@app/shared/models/api/companies/update-company-address';
import { ICompanyAddress } from '@app/shared/models/company-address';
import { createAction, props } from '@ngrx/store';

export const getCompanyAddresses = createAction('[CompanyAddresses] Get company addresses');
export const getCompanyAddressesSuccess = createAction(
  '[CompanyAddresses] Get company addresses is success',
  props<{ companyAddresses: Array<ICompanyAddress> }>()
);

export const getCompanyAddress = createAction('[CompanyAddresses] Get company address', props<{ id: string }>());
export const getCompanyAddressSuccess = createAction(
  '[CompanyAddresses] Get company address is success',
  props<{ companyAddress: ICompanyAddress }>()
);

export const deselectCompanyAddress = createAction('[CompanyAddresses] Deselect company address');

export const addCompanyAddress = createAction(
  '[CompanyAddresses] Add company address',
  props<{ companyAddress: ICreateCompanyAddressRequest }>()
);
export const addCompanyAddressSuccess = createAction(
  '[CompanyAddresses] Add company address is success',
  props<{ companyAddress: ICompanyAddress }>()
);

export const editCompanyAddress = createAction(
  '[CompanyAddresses] Edit company address',
  props<{ id: string, companyAddress: IUpdateCompanyAddressRequest }>()
);
export const editCompanyAddressSuccess = createAction(
  '[CompanyAddresses] Edit company address is success',
  props<{ companyAddress: ICompanyAddress }>()
);
