import { ICreateCompanyAddressRequest } from '@app/shared/models/api/companies/create-company-address';
import { IUpdateCompanyAddressRequest } from '@app/shared/models/api/companies/update-company-address';
import { ICompanyAddress } from '@app/shared/models/company-address';
import { Action, createReducer, on } from '@ngrx/store';
import { ICompanyAddressesState } from '@storeModule/company-addresses/company-addresses-state.interface';
import {
  addCompanyAddress,
  addCompanyAddressSuccess,
  deselectCompanyAddress,
  editCompanyAddress,
  editCompanyAddressSuccess,
  getCompanyAddressesSuccess,
  getCompanyAddressSuccess
} from '@storeModule/company-addresses/company-addresses.actions';
import { adapter, initialState } from '@storeModule/company-addresses/company-addresses.state';

export function reducer(state: ICompanyAddressesState | undefined, action: Action) {
  return companyAddressesReducer(state, action);
}

const companyAddressesReducer = createReducer(
  initialState,
  on(getCompanyAddressesSuccess, (state: ICompanyAddressesState, props: { companyAddresses: Array<ICompanyAddress> }) => {
    state.loaded = true;
    return adapter.addAll(props.companyAddresses, state);
  }),
  on(getCompanyAddressSuccess, (state: ICompanyAddressesState, props: { companyAddress: ICompanyAddress }) => {
    state.selectedCompanyAddress = props.companyAddress;
    if (state.entities[props.companyAddress.id]) {
      return adapter.updateOne({
        id: props.companyAddress.id,
        changes: props.companyAddress
      }, state);
    } else {
      return adapter.addOne(props.companyAddress, state);
    }
  }),
  on(deselectCompanyAddress, (state: ICompanyAddressesState) => {
    return {...state, selectedCompanyAddress: null};
  }),
  on(addCompanyAddress, (state: ICompanyAddressesState, props: { companyAddress: ICreateCompanyAddressRequest }) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(addCompanyAddressSuccess, (state: ICompanyAddressesState, props: { companyAddress: ICompanyAddress }) => {
    state.isAddOrEditInProgress = false;
    return adapter.addOne(props.companyAddress, state);
  }),
  on(editCompanyAddress, (state: ICompanyAddressesState, props: { id: string, companyAddress: IUpdateCompanyAddressRequest }) => {
    return {...state, isAddOrEditInProgress: true};
  }),
  on(editCompanyAddressSuccess, (state: ICompanyAddressesState, props: { companyAddress: ICompanyAddress }) => {
    state.isAddOrEditInProgress = false;
    return adapter.updateOne({
      id: props.companyAddress.id,
      changes: props.companyAddress
    }, state);
  }),
);
