import { createReducer, on } from '@ngrx/store';
import { getAddressTypesSuccess, getContactTypesSuccess, getCountriesSuccess, getSectorsSuccess, getWageTypesSuccess } from '@storeModule/dictionaries/dictionaries.actions';
import { dictionariesState } from '@storeModule/dictionaries/dictionaries.state';
export function reducer(state, action) {
    return dictionariesReducer(state, action);
}
const ɵ0 = (state, props) => {
    const preparedData = prepareEntitiesData(props.addressTypes);
    return Object.assign({}, state, { addressTypes: Object.assign({}, state.addressTypes, { entities: preparedData.entities, ids: preparedData.ids, loaded: true }) });
}, ɵ1 = (state, props) => {
    const preparedData = prepareEntitiesData(props.contactTypes);
    return Object.assign({}, state, { contactTypes: Object.assign({}, state.contactTypes, { entities: preparedData.entities, ids: preparedData.ids, loaded: true }) });
}, ɵ2 = (state, props) => {
    const preparedData = prepareEntitiesData(props.wageTypes);
    return Object.assign({}, state, { wageTypes: Object.assign({}, state.wageTypes, { entities: preparedData.entities, ids: preparedData.ids, loaded: true }) });
}, ɵ3 = (state, props) => {
    const preparedData = prepareEntitiesData(props.sectors);
    return Object.assign({}, state, { sectors: Object.assign({}, state.sectors, { entities: preparedData.entities, ids: preparedData.ids, loaded: true }) });
}, ɵ4 = (state, props) => {
    const preparedData = prepareCountriesData(props.countries);
    return Object.assign({}, state, { countries: Object.assign({}, state.countries, { entities: preparedData.entities, ids: preparedData.ids, loaded: true }) });
};
const dictionariesReducer = createReducer(dictionariesState, on(getAddressTypesSuccess, ɵ0), on(getContactTypesSuccess, ɵ1), on(getWageTypesSuccess, ɵ2), on(getSectorsSuccess, ɵ3), on(getCountriesSuccess, ɵ4));
function prepareEntitiesData(data) {
    const ids = [];
    const entities = {};
    data.forEach((item, i) => {
        ids.push(item.id);
        entities[item.id] = item;
    });
    return { ids, entities };
}
function prepareCountriesData(data) {
    const ids = [];
    const entities = {};
    data.forEach((item, i) => {
        ids.push(item.isoCode);
        entities[item.isoCode] = item;
    });
    return { ids, entities };
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
