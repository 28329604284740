import { EmailInfo } from '@app/shared/classes/email-info';
import { Employee } from '@app/shared/classes/employee';
import { GetEncodedEmployeesResponse } from '@app/shared/models/api/encoded-employees/get-encoded-employees';
import { ManageEntityTypes } from '@employerPortalModule/shared/enums/manage-entity-types';
import { createAction, props } from '@ngrx/store';

export const getEmployees = createAction('[Employees] Get employees', props<{ limit?: number, offset?: number }>());
export const getEmployeesOffsetSuccess =
  createAction('[Employees] Get employees offset success', props<{ encodedEmployees: GetEncodedEmployeesResponse }>());
export const getEmployeesSuccess = createAction('[Employees] Get employees is success', props<{ employees: Array<Employee> }>());

export const changeStatusOfEmployee = createAction('[Employees] Change status of employee', props<{ id: string }>());
export const changeStatusOfEmployeeSuccess =
  createAction('[Employees] Change status of employee is success', props<{ employee: Employee }>());

export const addEmployee = createAction('[Employees] Add employee', props<{ employee: Employee }>());
export const addEmployeeSuccess =
  createAction('[Employees] Add employee is success', props<{ employee: Employee }>());

export const editEmployee = createAction('[Employees] Edit employee', props<{ id: string, employee: Employee }>());
export const editEmployeeSuccess = createAction('[Employees] Edit employee is success', props<{ employee: Employee }>());

export const importEmployees = createAction('[Employees] Import employees', props<{
  [ManageEntityTypes.Add]: Array<Employee>,
  [ManageEntityTypes.Edit]: Array<Employee>,
  notValid: Array<{employee: Employee, reason: string}>,
}>());
export const importEmployeesSuccess = createAction('[Employees] Import employees is success', props<{
  added: Array<Employee>,
  edited: Array<Employee>,
  notValid: Array<{employee: Employee, reason: string}>,
}>());
export const clearImportResult = createAction('[Employees] Clear import result');

export const addEmployeesSuccess = createAction('[Employees] Add employees is success', props<{employees: Array<Employee>}>());
export const editEmployeesSuccess = createAction('[Employees] Edit employees is success', props<{employees: Array<Employee>}>());

export const getEmployee = createAction('[Employees] Get employee', props<{ id: string }>());
export const getEmployeeSuccess = createAction('[Employees] Get employee is success', props<{ employee: Employee }>());

export const deselectEmployee = createAction('[Employees] Deselect employee');

export const sendEmailToEmployees = createAction('[Employees] Send mail to employee', props<{ employees: Array<EmailInfo>, notification?: boolean}>());
