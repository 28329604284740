import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EmployeesEffects } from '@storeModule/employees/employees.effects';
import { reducer } from '@storeModule/employees/employees.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('employees', reducer),
    EffectsModule.forFeature([EmployeesEffects])
  ]
})
export class EmployeesStoreModule {
}
