import { createSelector } from '@ngrx/store';
import { ICompanyState } from '@storeModule/company/company-state.interface';
import { IStoreState } from '@storeModule/store-state';

export const selectCompanyState = (state: IStoreState): ICompanyState => state.company;

export const selectIsEditInProgress = createSelector(
  selectCompanyState,
  (state: ICompanyState) => state.isEditInProgress
);

export const selectCompany = createSelector(
  selectCompanyState,
  (state: ICompanyState) => state.company
);
