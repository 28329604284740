import {Injectable} from '@angular/core';
import {EncodedEmployeesApiService} from '@app/core/api/encoded-employees/encoded-employees-api.service';
import { SendingMailsApiService } from '@app/core/api/sending-mails/sending-mails-api.service';
import {EmailInfo} from '@app/shared/classes/email-info';
import {Employee} from '@app/shared/classes/employee';
import {GetEncodedEmployeesResponse} from '@app/shared/models/api/encoded-employees/get-encoded-employees';
import { IPaginationRequest } from '@app/shared/models/api/pagination-request';
import {IEncodedEmployee} from '@app/shared/models/encoded-employee';
import {CryptService} from '@app/shared/services/crypt/crypt.service';
import { ManageEntityTypes } from '@employerPortalModule/shared/enums/manage-entity-types';
import { NotificationTypes } from '@employerPortalModule/shared/enums/notification-types';
import {ManageEncodedEmployeeService} from '@employerPortalModule/shared/services/manage-encoded-employee/manage-encoded-employee.service';
import { environment } from '@environment';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import {selectCKey} from '@storeModule/company-key/company-key.selectors';
import {
  addEmployee,
  addEmployeesSuccess,
  addEmployeeSuccess,
  changeStatusOfEmployee,
  changeStatusOfEmployeeSuccess,
  editEmployee,
  editEmployeesSuccess,
  editEmployeeSuccess,
  getEmployee,
  getEmployees,
  getEmployeesOffsetSuccess,
  getEmployeesSuccess, getEmployeeSuccess, importEmployees, importEmployeesSuccess, sendEmailToEmployees
} from '@storeModule/employees/employees.actions';
import {
  selectEmployeeById,
  selectIsEmployeesLoaded,
  selectPendingToDecodeEmployees
} from '@storeModule/employees/employees.selectors';
import {IStoreState} from '@storeModule/store-state';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, of, zip } from 'rxjs';
import { concatMap, filter, first, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { selectCompany } from '@storeModule/company/company.selectors';

const paginationConfig: IPaginationRequest = {
  limit: 500,
  offset: 0
};

@Injectable()
export class EmployeesEffects {
  getEmployees$ = createEffect(() => this.actions$
    .pipe(
      ofType(getEmployees),
      withLatestFrom(this.store.select(selectIsEmployeesLoaded)),
      filter(([action, isEmployeesLoaded]) => !isEmployeesLoaded),
      map(([action]) => {
        return {
          limit: action.hasOwnProperty('limit') ? action.limit : paginationConfig.limit,
          offset: action.hasOwnProperty('offset') ? action.offset : paginationConfig.offset
        };
      }),
      switchMap(config => {
        return zip(
          of(config),
          this.encodedEmployeesApiService.getEncodedEmployees(config),
          this.store.select(selectPendingToDecodeEmployees).pipe(first()),
          this.store.select(selectCKey).pipe(first())
        );
      }),
      switchMap(([
                   config,
                   encodedEmployees,
                   pendingEncodedEmployees,
                   CKey]: [{ limit: number, offset: number }, GetEncodedEmployeesResponse, GetEncodedEmployeesResponse, string]) => {
        if (encodedEmployees.length === config.limit) {
          return [
            getEmployeesOffsetSuccess({encodedEmployees}),
            getEmployees({...config, offset: config.offset + config.limit})
          ];
        }

        const employees = [...encodedEmployees, ...pendingEncodedEmployees]
          .map((encodedEmployee) => this.manageEncodedEmployee.decode(encodedEmployee, CKey));

        return [getEmployeesSuccess({employees})];

      })
    ));

  getEmployee$ = createEffect(() => this.actions$
    .pipe(
      ofType(getEmployee),
      map(action => action.id),
      concatMap((id: string) => this.encodedEmployeesApiService.getEncodedEmployee(id)),
      withLatestFrom(this.store.select(selectCKey)),
      map(([encodedEmployee, CKey]: [IEncodedEmployee, string]) => {
        const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

        return getEmployeeSuccess({employee});
      })
    ));

  changeStatusOfEmployee$ = createEffect(() => this.actions$
    .pipe(
      ofType(changeStatusOfEmployee),
      switchMap((action) => combineLatest(this.store.select(selectEmployeeById, action.id).pipe(take(1)), this.store.select(selectCKey))),
      concatMap(([selectedEmployee, CKey]: [Employee, string]) => {
        const preparedEmployee = this.manageEncodedEmployee.prepareEmployeeRequestAfterChangeStatus(selectedEmployee, CKey);

        return forkJoin(this.encodedEmployeesApiService.updateEncodedEmployee(selectedEmployee.id, preparedEmployee), of(CKey));
      }),
      map(([encodedEmployee, CKey]: [IEncodedEmployee, string]) => {
        const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

        return changeStatusOfEmployeeSuccess({employee});
      })
    ));

  addEmployee$ = createEffect(() => this.actions$
    .pipe(
      ofType(addEmployee),
      map(action => action.employee),
      withLatestFrom(this.store.select(selectCKey)),
      concatMap(([employee, CKey]: [Employee, string]) => {
        const EKey = this.cryptService.keyBytesToKeyBase64(this.cryptService.generateKey());
        employee.EKey = EKey;

        const preparedEmployee = this.manageEncodedEmployee.prepareEmployeeToRequest(employee, CKey);

        return forkJoin(this.encodedEmployeesApiService.createEncodedEmployee(preparedEmployee), of(CKey), of(EKey), of(employee.email));
      }),
      map(([encodedEmployee, CKey, EKey, email]: [IEncodedEmployee, string, string, string]) => {
        const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

        return addEmployeeSuccess({employee});
      })
    ));

  addEmployeeSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(addEmployeeSuccess),
      map(action => action.employee),
      map((employee: Employee) => {
        return sendEmailToEmployees({
          employees: [new EmailInfo(employee.id, employee.email, employee.EKey, employee.firstName, employee.lastName)]
        });
      }),
      tap(() => this.toastr.success(null, NotificationTypes.successEmployeeAdded))
    ));

  editEmployeeSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(editEmployeeSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successEmployeeEdited))
    ), {dispatch: false});

  addEmployeesSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(addEmployeesSuccess),
      map(action => action.employees),
      map((employees: Array<Employee>) => {
        return sendEmailToEmployees({
          employees: employees.map(employee => new EmailInfo(employee.id, employee.email, employee.EKey, employee.firstName, employee.lastName))
        });
      })
    ));

  sendEmailToEmployees$ = createEffect(() => this.actions$
    .pipe(
      ofType(sendEmailToEmployees),
      withLatestFrom(this.store.select(selectCompany)),
      map(([action, company]) => [action.employees, action.notification, company.name]),
      filter(([employees, notification, companyName]: [Array<EmailInfo>, boolean, string]) => !!employees.length),
      switchMap(([employees, notification, companyName]: [Array<EmailInfo>, boolean, string]) => {
        return forkJoin(this.emailApiService.sendMails(employees.map((employee: EmailInfo) => {
          return {
            to: environment.commonTargetEmail || employee.email,
            fullName: employee.firstName + ' ' + employee.lastName,
            companyName,
            agreeUrl: this.emailApiService.getAgreeUrl(
              employee.id,
              this.cryptService.base64ToBase64url(employee.EKey),
              this.cryptService.base64ToBase64url(this.cryptService.keyBytesToKeyBase64(environment.emailRedirectTo)))
          };
        })), of(notification));
      }),
      map(([response, notification]) => {
        if (notification) {
          this.toastr.success(null, NotificationTypes.successEmailSent);
        }
      })
    ), {dispatch: false});

  editEmployee$ = createEffect(() => this.actions$
    .pipe(
      ofType(editEmployee),
      withLatestFrom(this.store.select(selectCKey)),
      concatMap(([action, CKey]) => {
        const preparedEmployee = this.manageEncodedEmployee.prepareEmployeeToRequest(action.employee, CKey);

        return forkJoin(this.encodedEmployeesApiService.updateEncodedEmployee(action.id, preparedEmployee), of(CKey));
      }),
      map(([encodedEmployee, CKey]) => {
        const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

        return editEmployeeSuccess({employee});
      })
    ));

  importEmployee$ = createEffect(() => this.actions$
    .pipe(
      ofType(importEmployees),
      withLatestFrom(this.store.select(selectCKey)),
      concatMap(([action, CKey]) => {
        const employeesForCreating = action[ManageEntityTypes.Add].map((employee) => {
          employee.EKey = this.cryptService.keyBytesToKeyBase64(this.cryptService.generateKey());

          return this.manageEncodedEmployee.prepareEmployeeToRequest(employee, CKey);
        });

        const employeesForEditing = action[ManageEntityTypes.Edit].map((employee) => {
          const preparedEmployee = this.manageEncodedEmployee.prepareEmployeeToRequest(employee, CKey);
          preparedEmployee.id = employee.id;

          return preparedEmployee;
        });

        return forkJoin(
          of(action[ManageEntityTypes.Add]),
          employeesForCreating.length ? this.encodedEmployeesApiService.createEncodedEmployees(employeesForCreating) : of({succeed: [], failed: []}),
          of(action[ManageEntityTypes.Edit]),
          employeesForEditing ? this.encodedEmployeesApiService.updateEncodedEmployees(employeesForEditing) : of({succeed: [], failed: []}),
          of(action.notValid),
          of(CKey));
      }),
      switchMap(([employeesForCreating, createdEncodedEmployeesResponse, employeesForEditing, updatedEncodedEmployeesResponse, notValid, CKey]) => {
        const nextActions: Action[] = [];
        const successAdd = [];
        const successEdit = [];

        createdEncodedEmployeesResponse.succeed.forEach((encodedEmployee) => {
          const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

          successAdd.push(employee);
        });

        createdEncodedEmployeesResponse.failed.forEach((errorResponse) => {
          notValid.push(
            {
              employee: employeesForCreating[errorResponse.index],
              reason: errorResponse.errorDescription || 'server does not allow to create'
            });
        });

        updatedEncodedEmployeesResponse.succeed.forEach((encodedEmployee) => {
          const employee = this.manageEncodedEmployee.decode(encodedEmployee, CKey);

          successEdit.push(employee);
        });

        updatedEncodedEmployeesResponse.failed.forEach((errorResponse) => {
          notValid.push(
            {
              employee: employeesForEditing[errorResponse.index],
              reason: errorResponse.errorDescription || 'server does not allow to update'
            });
        });

        nextActions.push(addEmployeesSuccess({employees: successAdd}));
        nextActions.push(editEmployeesSuccess({employees: successEdit}));
        nextActions.push(importEmployeesSuccess({added: successAdd, edited: successEdit, notValid}));

        return nextActions;
      })
    ));

  importEmployeeSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType(importEmployeesSuccess),
      tap(() => this.toastr.success(null, NotificationTypes.successEmployeesImported))
    ), {dispatch: false});

  constructor(
    private store: Store<IStoreState>,
    private actions$: Actions,
    private encodedEmployeesApiService: EncodedEmployeesApiService,
    private emailApiService: SendingMailsApiService,
    private manageEncodedEmployee: ManageEncodedEmployeeService,
    private cryptService: CryptService,
    private toastr: ToastrService
  ) {
  }
}
