import { Injectable } from '@angular/core';
import { ProfileApiService } from '@app/core/api/profile/profile-api.service';
import { IProfileResponse } from '@app/shared/models/api/profile/profile-response';
import { IUserService } from '@app/shared/services/user/user-service';
import { Observable } from 'rxjs';

@Injectable()
export class UserService implements IUserService {

  constructor(private profileApi: ProfileApiService) {
  }

  getUser(): Observable<IProfileResponse> {
    return this.profileApi.getProfile();
  }
}
