import { EmployeeStatus } from '@app/shared/enums/encoded-employee-status.enum';

export class Employee {
  id: string;
  status: EmployeeStatus;
  employeeNumber: string;
  mwAgreementDone: boolean;
  firstName: string;
  lastName: string;
  created: number;
  mwAgreementDate?: number;
  linkedToMWAccount?: boolean;
  linkedToMWAccountDate?: number;
  DOB?: number;
  email: string;
  phoneNumber?: string;
  country: string;
  city?: string;
  postCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  wageTypeId: string;
  wageValue: number;
  bankCountryCode: string;
  bankSortCode: string;
  bankAccountNumber: string;
  securityNumber?: string;
  payrollNumber: string;
  employmentStartDate: number;
  employmentEndDate?: number;
  EKey: string;

  constructor(id?: string,
              firstName?: string,
              lastName?: string,
              status?: EmployeeStatus,
              employeeNumber?: string,
              mwAgreementDone?: boolean,
              created?: number,
              mwAgreementDate?: number,
              linkedToMWAccount?: boolean,
              linkedToMWAccountDate?: number,
              DOB?: number,
              email?: string,
              phoneNumber?: string,
              country?: string,
              city?: string,
              postCode?: string,
              addressLine1?: string,
              addressLine2?: string,
              wageTypeId?: string,
              wageValue?: number,
              bankCountryCode?: string,
              bankSortCode?: string,
              bankAccountNumber?: string,
              securityNumber?: string,
              payrollNumber?: string,
              employmentStartDate?: number,
              employmentEndDate?: number,
              EKey?: string
  ) {

    this.id = id === undefined ? null : id;
    this.firstName = firstName === undefined ? null : firstName;
    this.lastName = lastName === undefined ? null : lastName;
    this.status = status === undefined ? null : status;
    this.employeeNumber = employeeNumber === undefined ? null : employeeNumber;
    this.mwAgreementDone = mwAgreementDone === undefined ? null : mwAgreementDone;
    this.created = created === undefined ? null : created;
    this.mwAgreementDate = mwAgreementDate === undefined ? null : mwAgreementDate;
    this.linkedToMWAccount = linkedToMWAccount === undefined ? null : linkedToMWAccount;
    this.linkedToMWAccountDate = linkedToMWAccountDate === undefined ? null : linkedToMWAccountDate;
    this.DOB = DOB === undefined ? null : DOB;
    this.email = email === undefined ? null : email;
    this.phoneNumber = phoneNumber === undefined ? null : phoneNumber;
    this.country = country === undefined ? null : country;
    this.city = city === undefined ? null : city;
    this.postCode = postCode === undefined ? null : postCode;
    this.addressLine1 = addressLine1 === undefined ? null : addressLine1;
    this.addressLine2 = addressLine2 === undefined ? null : addressLine2;
    this.wageTypeId = wageTypeId === undefined ? null : wageTypeId;
    this.wageValue = wageValue === undefined ? null : wageValue;
    this.bankCountryCode = bankCountryCode === undefined ? null : bankCountryCode;
    this.bankSortCode = bankSortCode === undefined ? null : bankSortCode;
    this.bankAccountNumber = bankAccountNumber === undefined ? null : bankAccountNumber;
    this.securityNumber = securityNumber === undefined ? null : securityNumber;
    this.payrollNumber = payrollNumber === undefined ? null : payrollNumber;
    this.employmentStartDate = employmentStartDate === undefined ? null : employmentStartDate;
    this.employmentEndDate = employmentEndDate === undefined ? null : employmentEndDate;
    this.EKey = EKey === undefined ? null : EKey;
  }
}

