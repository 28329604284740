import { ICompanyContact } from '@app/shared/models/company-contact';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICompanyContactsState } from '@storeModule/company-contacts/company-contacts-state.interface';
import { adapter } from '@storeModule/company-contacts/company-contacts.state';

export const selectCompanyContactsState = createFeatureSelector<ICompanyContactsState>('companyContacts');

export const selectIsLoaded = (state: ICompanyContactsState): boolean => state.loaded;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


export const selectCompanyContactIds = createSelector(
  selectCompanyContactsState,
  selectIds
);
export const selectCompanyContactEntities = createSelector(
  selectCompanyContactsState,
  selectEntities
);
export const selectAllCompanyContacts = createSelector(
  selectCompanyContactsState,
  selectAll
);
export const selectCompanyContactsTotal = createSelector(
  selectCompanyContactsState,
  selectTotal
);
export const selectIsCompanyContactsLoaded = createSelector(
  selectCompanyContactsState,
  selectIsLoaded
);
export const isAddOrEditInProgress = createSelector(
  selectCompanyContactsState,
  (state: ICompanyContactsState) => state.isAddOrEditInProgress
);
export const selectSelectedCompanyContact = createSelector(
  selectCompanyContactsState,
  (state: ICompanyContactsState) => state.selectedCompanyContact
);
export const selectCompanyContactById = createSelector(
  selectCompanyContactEntities,
  (state: Dictionary<ICompanyContact>, id: string) => state[id]
);
