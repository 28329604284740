import { Injectable } from '@angular/core';

import { CompaniesApiService } from '@app/core/api/companies/companies-api.service';
import { Company } from '@app/shared/classes/company';
import { CkeyValidationService } from '@app/shared/services/ckey-validation/ckey-validation.service';
import { CryptService } from '@app/shared/services/crypt/crypt.service';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  generateCompanyKey,
  generateCompanyKeySuccess,
  removeCompanyKey,
  saveCompanyKey,
  uploadCompanyKey,
  uploadCompanyKeyFail,
  uploadCompanyKeySuccess
} from '@storeModule/company-key/company-key.actions';

import { editCompany, editCompanySuccess } from '@storeModule/company/company.actions';
import { selectCompany } from '@storeModule/company/company.selectors';

import { IStoreState } from '@storeModule/store-state';
import { of, zip } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CompanyKeyEffects {
  generateCompanyKey$ = createEffect(() => this.actions$
    .pipe(
      ofType(generateCompanyKey),
      withLatestFrom(this.store.select(selectCompany)),
      switchMap(([action, company]: [Action, Company]) => {
        const CKey = this.cryptService.generateKey();
        company.keyControl = this.cryptService.encrypt(company.id, CKey);
        this.store.dispatch(editCompany({company: new Company(company)}));

        return zip(of(CKey), this.actions$.pipe(ofType(editCompanySuccess), take(1)));

      }),
      switchMap(([CKey, action]: [string, Action]) => {
        return [saveCompanyKey({CKey}), generateCompanyKeySuccess()];
      })
    ));

  uploadCompanyKey$ = createEffect(() => this.actions$
    .pipe(
      ofType(uploadCompanyKey),
      map(action => action.CKey),
      withLatestFrom(this.store.select(selectCompany)),
      switchMap(([cKey, company]: [string, Company]) => {
        if (this.ckeyValidationService.isCKeyValid(company, cKey)) {
          const CKey = this.cryptService.keyBase64ToKeyBytes(cKey);
          return [saveCompanyKey({CKey}), uploadCompanyKeySuccess()];

        } else {
          return [removeCompanyKey(), uploadCompanyKeyFail()];
        }
      })
    ));

  saveCompanyKey$ = createEffect(() => this.actions$
    .pipe(
      ofType(saveCompanyKey),
      map(action => action.CKey),
      map((CKey) => {
        localStorage.setItem('CKey', this.cryptService.keyBytesToKeyBase64(CKey));
      }),
    ), {dispatch: false});

  removeCompanyKey$ = createEffect(() => this.actions$
    .pipe(
      ofType(removeCompanyKey),
      map(action => {
        localStorage.removeItem('CKey');
      }),
    ), {dispatch: false});

  constructor(
    private store: Store<IStoreState>,
    private companiesApiService: CompaniesApiService,
    private cryptService: CryptService,
    private actions$: Actions,
    private ckeyValidationService: CkeyValidationService
  ) {
  }
}
