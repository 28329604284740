import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyKeyEffects } from '@storeModule/company-key/company-key.effects';

import { reducer } from '@storeModule/company-key/company-key.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('companyKey', reducer),
    EffectsModule.forFeature([CompanyKeyEffects])
  ],
  exports: [StoreModule]
})
export class CompanyKeyStoreModule {
}
