import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/shared/classes/company';
import { ICreateCompanyAddressRequest } from '@app/shared/models/api/companies/create-company-address';
import { ICreateCompanyContactRequest } from '@app/shared/models/api/companies/create-company-contact';
import { IDeleteCompanyAddressResponse } from '@app/shared/models/api/companies/delete-company-address';
import { IDeleteCompanyContactResponse } from '@app/shared/models/api/companies/delete-company-contact';

import { GetCompanyAddressesResponse } from '@app/shared/models/api/companies/get-company-addresses';

import { GetCompanyContactsResponse } from '@app/shared/models/api/companies/get-company-contacts';

import { IUpdateCompanyRequest } from '@app/shared/models/api/companies/update-company';
import { IUpdateCompanyAddressRequest } from '@app/shared/models/api/companies/update-company-address';
import { IUpdateCompanyContactRequest } from '@app/shared/models/api/companies/update-company-contact';

import { ICompanyAddress } from '@app/shared/models/company-address';
import { ICompanyContact } from '@app/shared/models/company-contact';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompaniesApiService {

  constructor(private httpClient: HttpClient) {
  }

  getCompany(): Observable<Company> {
    return this.httpClient.get<Company>(`${environment.apiBaseUrl}/management/v1/company`);
  }

  updateCompany(request: IUpdateCompanyRequest): Observable<Company> {
    return this.httpClient.put<Company>(`${environment.apiBaseUrl}/management/v1/company`, request);
  }

  getCompanyAddresses(): Observable<GetCompanyAddressesResponse> {
    return this.httpClient.get<GetCompanyAddressesResponse>(`${environment.apiBaseUrl}/management/v1/company/addresses`);
  }

  createCompanyAddress(request: ICreateCompanyAddressRequest): Observable<ICompanyAddress> {
    return this.httpClient.post<ICompanyAddress>(`${environment.apiBaseUrl}/management/v1/company/addresses/`, request);
  }

  getCompanyAddress(addressId: string): Observable<ICompanyAddress> {
    return this.httpClient.get<ICompanyAddress>(`${environment.apiBaseUrl}/management/v1/company/addresses/${addressId}`);
  }

  updateCompanyAddress(addressId: string, request: IUpdateCompanyAddressRequest): Observable<ICompanyAddress> {
    return this.httpClient.put<ICompanyAddress>(`${environment.apiBaseUrl}/management/v1/company/addresses/${addressId}`, request);
  }

  deleteCompanyAddress(addressId: string): Observable<IDeleteCompanyAddressResponse> {
    return this.httpClient.delete<IDeleteCompanyAddressResponse>(`${environment.apiBaseUrl}/management/v1/company/addresses/${addressId}`);
  }

  getCompanyContacts(): Observable<GetCompanyContactsResponse> {
    return this.httpClient.get<GetCompanyContactsResponse>(`${environment.apiBaseUrl}/management/v1/company/contacts`);
  }

  createCompanyContact(request: ICreateCompanyContactRequest): Observable<ICompanyContact> {
    return this.httpClient.post<ICompanyContact>(`${environment.apiBaseUrl}/management/v1/company/contacts/`, request);
  }

  getCompanyContact(contactId: string): Observable<ICompanyContact> {
    return this.httpClient.get<ICompanyContact>(`${environment.apiBaseUrl}/management/v1/company/contacts/${contactId}`);
  }

  updateCompanyContact(contactId: string, request: IUpdateCompanyContactRequest): Observable<ICompanyContact> {
    return this.httpClient.put<ICompanyContact>(`${environment.apiBaseUrl}/management/v1/company/contacts/${contactId}`, request);
  }

  deleteCompanyContact(companyId: string): Observable<IDeleteCompanyContactResponse> {
    return this.httpClient.delete<IDeleteCompanyContactResponse>(`${environment.apiBaseUrl}/management/v1/company/contacts/${companyId}`);
  }
}

