import { KeyLoadedBy } from '@app/shared/enums/key-loaded-by.enum';
import { createReducer, on } from '@ngrx/store';
import { generateCompanyKeySuccess, removeCompanyKey, saveCompanyKey, uploadCompanyKeyFail, uploadCompanyKeySuccess } from '@storeModule/company-key/company-key.actions';
import { companyKeyState } from '@storeModule/company-key/company-key.state';
export function reducer(state, action) {
    return companyKeyReducer(state, action);
}
const ɵ0 = (state, props) => {
    return Object.assign({}, state, { CKey: props.CKey, isKeyLoaded: true });
}, ɵ1 = (state) => {
    return Object.assign({}, state, { CKey: null, isKeyLoaded: false, keyLoadingBy: null });
}, ɵ2 = (state) => {
    return Object.assign({}, state, { keyLoadingBy: KeyLoadedBy.Generation });
}, ɵ3 = (state) => {
    return Object.assign({}, state, { keyLoadingBy: KeyLoadedBy.Uploading });
}, ɵ4 = (state) => {
    return Object.assign({}, state, { keyLoadingBy: KeyLoadedBy.Uploading });
};
const companyKeyReducer = createReducer(companyKeyState, on(saveCompanyKey, ɵ0), on(removeCompanyKey, ɵ1), on(generateCompanyKeySuccess, ɵ2), on(uploadCompanyKeySuccess, ɵ3), on(uploadCompanyKeyFail, ɵ4));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
