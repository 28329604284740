import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICreateEncodedEmployeeRequest } from '@app/shared/models/api/encoded-employees/create-encoded-employee';
import {
  ICreateEncodedEmployeesRequest,
  ICreateEncodedEmployeesResponse
} from '@app/shared/models/api/encoded-employees/create-encoded-employees';
import { GetEncodedEmployeesResponse } from '@app/shared/models/api/encoded-employees/get-encoded-employees';
import { IUpdateEncodedEmployeeRequest } from '@app/shared/models/api/encoded-employees/update-encoded-employee';
import {
  UpdateEncodedEmployeesRequest,
  UpdateEncodedEmployeesResponse
} from '@app/shared/models/api/encoded-employees/update-encoded-employees';
import { IPaginationRequest } from '@app/shared/models/api/pagination-request';

import { IEncodedEmployee } from '@app/shared/models/encoded-employee';
import { environment } from '@environment';
import { Observable } from 'rxjs';


@Injectable()
export class EncodedEmployeesApiService {

  constructor(private httpClient: HttpClient) {
  }

  getEncodedEmployees(request: IPaginationRequest = {}): Observable<GetEncodedEmployeesResponse> {
    let params = new HttpParams();

    if (request.limit) {
      params = params.append('limit', request.limit.toString());
    }
    if (request.offset) {
      params = params.append('offset', request.offset.toString());
    }

    return this.httpClient.get<GetEncodedEmployeesResponse>(`${environment.apiBaseUrl}/management/v1/encodedEmployees`, {params});
  }

  getEncodedEmployee(employeeId: string): Observable<IEncodedEmployee> {
    return this.httpClient.get<IEncodedEmployee>(`${environment.apiBaseUrl}/management/v1/encodedEmployees/${employeeId}`);
  }

  createEncodedEmployee(request: ICreateEncodedEmployeeRequest): Observable<IEncodedEmployee> {
    return this.httpClient.post<IEncodedEmployee>(`${environment.apiBaseUrl}/management/v1/encodedEmployees`, request);
  }

  createEncodedEmployees(request: ICreateEncodedEmployeesRequest): Observable<ICreateEncodedEmployeesResponse> {
    return this.httpClient.post<ICreateEncodedEmployeesResponse>(`${environment.apiBaseUrl}/management/v1/encodedEmployeesBulk`, request);
  }

  updateEncodedEmployee(employeeId: string, request: IUpdateEncodedEmployeeRequest): Observable<IEncodedEmployee> {
    return this.httpClient.put<IEncodedEmployee>(`${environment.apiBaseUrl}/management/v1/encodedEmployees/${employeeId}`, request);
  }

  updateEncodedEmployees(request: UpdateEncodedEmployeesRequest): Observable<UpdateEncodedEmployeesResponse> {
    return this.httpClient.put<UpdateEncodedEmployeesResponse>(`${environment.apiBaseUrl}/management/v1/encodedEmployeesBulk`, request);
  }
}
