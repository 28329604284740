export class EncodedByEKey {
  firstName: string;
  lastName: string;
  DOB?: number; // unix time seconds[UTC]
  email: string;
  phoneNumber?: string;
  country: string;
  city?: string;
  postCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  wageTypeId: string;
  wageValue: number;
  bankCountryCode: string;
  bankSortCode: string;
  bankAccountNumber: string;
  securityNumber?: string;
  payrollNumber: string;
  employmentStartDate: number; // unix time seconds[UTC]
  employmentEndDate?: number; // unix time seconds[UTC]

  constructor(firstName: string,
              lastName: string,
              DOB: number,
              email: string,
              phoneNumber: string,
              country: string,
              city: string,
              postCode: string,
              addressLine1: string,
              addressLine2: string,
              wageTypeId: string,
              wageValue: number,
              bankCountryCode: string,
              bankSortCode: string,
              bankAccountNumber: string,
              securityNumber: string,
              payrollNumber: string,
              employmentStartDate: number,
              employmentEndDate: number) {

    this.firstName = firstName;
    this.lastName = lastName;

    if (DOB !== null) {
      this.DOB = DOB;
    }
    this.email = email;
    if (phoneNumber !== null) {
      this.phoneNumber = phoneNumber;
    }
    this.country = country;
    if (city !== null) {
      this.city = city;
    }
    if (postCode !== null) {
      this.postCode = postCode;
    }
    if (addressLine1 !== null) {
      this.addressLine1 = addressLine1;
    }
    if (addressLine2 !== null) {
      this.addressLine2 = addressLine2;
    }
    this.wageTypeId = wageTypeId;
    this.wageValue = wageValue;
    this.bankCountryCode = bankCountryCode;
    this.bankSortCode = bankSortCode;
    this.bankAccountNumber = bankAccountNumber;

    if (securityNumber !== null) {
      this.securityNumber = securityNumber;
    }
    this.payrollNumber = payrollNumber;
    this.employmentStartDate = employmentStartDate;
    if (employmentEndDate !== null) {
      this.employmentEndDate = employmentEndDate;
    }
  }
}

export class EncodedByCKey extends EncodedByEKey {
  EKey: string;

  constructor(firstName: string,
              lastName: string,
              DOB: number,
              email: string,
              phoneNumber: string,
              country: string,
              city: string,
              postCode: string,
              addressLine1: string,
              addressLine2: string,
              wageTypeId: string,
              wageValue: number,
              bankCountryCode: string,
              bankSortCode: string,
              bankAccountNumber: string,
              securityNumber: string,
              payrollNumber: string,
              employmentStartDate: number,
              employmentEndDate: number,
              EKey: string) {

    super(
      firstName,
      lastName,
      DOB,
      email,
      phoneNumber,
      country,
      city,
      postCode,
      addressLine1,
      addressLine2,
      wageTypeId,
      wageValue,
      bankCountryCode,
      bankSortCode,
      bankAccountNumber,
      securityNumber,
      payrollNumber,
      employmentStartDate,
      employmentEndDate);

    this.EKey = EKey;
  }
}
