import { Company } from '@app/shared/classes/company';
import { createReducer, on } from '@ngrx/store';
import { editCompany, editCompanySuccess, getCompanySuccess, removeCompany } from '@storeModule/company/company.actions';
import { companyState } from '@storeModule/company/company.state';
export function reducer(state, action) {
    return companyReducer(state, action);
}
const ɵ0 = (state, props) => {
    return Object.assign({}, state, { company: props.company });
}, ɵ1 = (state, props) => {
    return Object.assign({}, state, { isEditInProgress: true });
}, ɵ2 = (state, props) => {
    return Object.assign({}, state, { company: props.company, isEditInProgress: false });
}, ɵ3 = (state) => {
    return Object.assign({}, state, { company: new Company() });
};
const companyReducer = createReducer(companyState, on(getCompanySuccess, ɵ0), on(editCompany, ɵ1), on(editCompanySuccess, ɵ2), on(removeCompany, ɵ3));
export { ɵ0, ɵ1, ɵ2, ɵ3 };
